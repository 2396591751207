import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Carousel } from 'react-responsive-carousel';
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import leftArrow from "../../assets/images/slider_arrow_left.svg";
import rightArrow from "../../assets/images/slider_arrow_right.svg";
import { products } from '../../jsondata/products';

export default function OurProducts() {
    const navigate = useNavigate();
    const [showImage, setShowImage] = useState(window.screen.width > 1024 ? false : true);
    const LeftArrow = ({ slideCount,currentSlide, ...props }) => {
       return <img src={leftArrow} {...props} alt=""/>
    }
    const RightArrow = ({ slideCount,currentSlide, ...props }) => {
     return  <img src={rightArrow} {...props} alt=""/>
    }
    
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: true,
        prevArrow: <LeftArrow />,
        nextArrow: <RightArrow />
    };

    function handleContactBar() {
        if (window.screen.width > 1024) {
            setShowImage(false)
        } else {
            setShowImage(true)
        }
    }
    window.addEventListener("resize", handleContactBar)
    return (
        <div className="solutions py-5 px-5" id="products" style={{ background: "#171717" }}>
            <Container className="mb-5">
                <Row className="">
                    <Col><h1 className="fw-bold m-0">Our Products</h1></Col>
                </Row>
            </Container>
            <Slider {...settings}>
                {products.map((product, index) => (<div className="d-flex justify-content-center" key={index}>
                    <Container className="mb-2">
                        <Row className="mb-1">
                            <Col className="d-flex justify-content-center align-items-center product-carousel" md="6">
                                {showImage ? <img src={product.img[0]} className="img-fluid mt-2" alt=""/> :
                                    <Carousel autoPlay responsive className="mt-2"  >
                                        {product.img.map((image, index) => (
                                            <div key={index}>
                                                <img alt="" src={image} className="img-fluid" />
                                            </div>
                                        ))}
                                    </Carousel>
                                }

                            </Col>
                            <Col md="5" className="d-flex prod-desc ms-auto flex-column align-items-start">
                                <div className="d-flex">
                                    <h1 className="m-0" style={{ fontFamily: 'Teko, sans-serif', fontSize: "3rem" }}>{product.name.substring(0, product.name.lastIndexOf(" "))}</h1>
                                    <h1 className="text-primary m-0 ms-3" style={{ fontFamily: 'Teko, sans-serif', fontSize: "3rem" }}>{parseInt(product.name.split(" ")[product.name.split(" ").length - 1])}</h1>
                                </div>
                                <h5 className="fw-light mb-4 text-primary" style={{ fontSize: "1.1rem" }}><strong>{product.tagline}</strong></h5>
                                <p className="readable-font">{product.desc}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-primary d-flex align-items-center justify-content-center">
                                 {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <a className="attention-link " style={{ cursor: 'pointer', textDecoration: 'none' }} onClick={() => navigate("/detail", { state: product })}>Click for Specifications</a>
                            </Col>
                        </Row>
                    </Container>
                </div>))}
            </Slider>
        </div>
    )
}