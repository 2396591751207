import cyborg250_1 from "../assets/images/products/cyborg250_1.png";
import cyborg250_2 from "../assets/images/products/cyborg250_2.png";
import cyborg250_3 from "../assets/images/products/cyborg250_3.png";

import cyborg500_1 from "../assets/images/products/cyborg500_1.jpg";
import cyborg500_2 from "../assets/images/products/cyborg500_2.jpg";
import cyborg500_3 from "../assets/images/products/cyborg500_3.jpg";

import cyborg1000_1 from "../assets/images/products/cyborg1000_1.png";
import cyborg1000_2 from "../assets/images/products/cyborg1000_2.jpg";
import cyborg1000_3 from "../assets/images/products/cyborg1000_3.jpg";

export const products = [
    {
        img: [cyborg250_1,cyborg250_2,cyborg250_3],
        desc: "Our mobile robots boast an impressive payload capacity of 250kg with very low self weight, making them the ideal solution for quick displacement material handling tasks. With a robust design and advanced technology, these robots are engineered to efficiently transport goods and materials, streamlining industrial processes and enhancing productivity.",
        name: "CYBORG 250",
        tagline: 'Lightest weight material mover'
    },
    {
        img: [cyborg500_1,cyborg500_2,cyborg500_3],
        desc: "Momentum Robotics' Cyborg-500 is a cutting-edge robot designed for versatile industrial applications. Engineered by experts in India, this advanced system combines precision engineering and intelligent programming. With state-of-the-art technology, it offers exceptional performance, making it a top choice for various automation and robotic tasks.",
        name: "CYBORG 500",
        tagline: 'Robot which suits all range of needs'
    },
    {
        img: [cyborg1000_1,cyborg1000_2,cyborg1000_3],
        desc: "Momentum Robotics presents the Cyborg 1000, a robust and sizable robot tailored for heavy-duty industrial tasks. Designed by Indian engineers, this machine boasts an impressive payload capacity of 1000 kg, showcasing the fusion of cutting-edge engineering and precise programming. It's the go-to solution for demanding automation needs.",
        name: "CYBORG 1000",
        tagline: 'Beast with precision'
    },
];
