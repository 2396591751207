import React from "react";
import { Button, Card } from "react-bootstrap";
import arrowIco from "../../assets/images/arrow_icon.png";

const industriesData = [
  {
    icon: "fas fa-robot",
    title: "Automotive Manufacturing Enabler",
    content:
      "We enable production and assembly lines logistics tasks with our collaborative mobile robots to handle raw material in and out.",
    link: "/blog/industry/Automotive_Manufacturing_Enabler",
  },
  {
    icon: "fas fa-truck-loading",
    title: "Heavy industry material mover",
    content:
      "We enable production and assembly lines logistics tasks with our collaborative mobile robots to handle raw material in and out.",
    link: "/blog/industry/heavy_industry_material_mover",
  },
  {
    icon: "fas fa-warehouse",
    title: "FMCG/Warehousing",
    content:
      "Our robotics fleet can be deployed to carry out various tasks precisly and efficeintly in warehouses.",
    link: "/blog/industry/fmcg_warehousing",
  },
];

export default function Industries() {
  const handleClick = (route) => {
    window.location.href = route;
  };
  return (
    <div
      className="industries d-flex justify-content-between px-5 flex-wrap py-5 mt-3"
      id="industries"
      style={{ background: "#121212" }}
    >
      {industriesData.map((item, i) => {
        return (
          <Card
            key={i}
            className="industry-card px-5 border-0"
            onClick={() => handleClick(item.link)}
          >
            <Card.Body className="d-flex flex-column">
              <div className="services-icons mb-3 w-100 mb-4">
                <i className={item.icon}></i>
              </div>
              <Card.Title className="text-white w-100 mb-4">
                {item.title}
              </Card.Title>
              <Card.Text className="text-white">{item.content}</Card.Text>
              <Button
                className="d-flex ms-auto mt-auto align-items-center justify-content-center"
                variant="primary"
                style={{ width: "40px", height: "40px" }}
                href={item.link}
              >
                <img src={arrowIco} alt="Go" width={25} />
              </Button>
            </Card.Body>
          </Card>
        );
      })}
    </div>
  );
}
