import image1 from '../assets/images/gallery/image.png';
import image2 from '../assets/images/gallery/irulas_500_with_handle.jpg';
import image3 from '../assets/images/gallery/irulas_1000_front.jpg';
import image4 from '../assets/images/gallery/irulas_1000_rear.jpg';
import image5 from '../assets/images/gallery/irulas.jpg';
import image6 from '../assets/images/gallery/MRS1.jpeg';
import image7 from '../assets/images/gallery/rear2.jpg';
import image8 from '../assets/images/gallery/Render.png';

export const data = [
    {
        image:image1,
        desc:"Mapping/Navigation Interface",
    },
    {
        image:image2,
        desc:"MRS IRRULAS 500",
    },
    {
        image:image3,
        desc:"IRRULAS 1000 FRONT",
    },
    {
        image:image4,
        desc:"IRRULAS 1000 REAR",
    },
    {
        image:image5,
        desc:"MAIN IRRULAS DESIGN",
    },
    {
        image:image6,
        desc:"MRS1 Build",
    },
    {
        image:image7,
        desc:"IRRULAS REAR ",
    },
    {
        image:image8,
        desc:"IRRULAS 300",
    }
];