import React from 'react';
import { Col, Row ,Container} from 'react-bootstrap';



const privacyPolicyData = [
  { key: "Last Updated", value: "Date : 28/11/2023" },
  { key: "Welcome", value: "At Momentum Robotics, we are committed to protecting the privacy and security of your personal information. This Privacy Policy explains how we collect, use, and safeguard your information when you use our website, products, and services." },
  
  { key: "Information We Collect", value: [
    { key: "Personal Information", value: "When you use our website or interact with our products and services, we may collect personal information, such as your name, contact information, and other details necessary for providing our services." },
    { key: "Usage Information", value: "We may collect information about how you use our website, products, and services, including log data, device information, and analytics." }
  ]},

  { key: "How We Use Your Information", value: [
    { key: "Service Provision", value: "We use your personal information to provide and improve our products and services, including order processing, customer support, and tailored solutions." },
    { key: "Analytics", value: "We may use collected data for analytical purposes to understand trends, improve user experience, and enhance our offerings." }
  ]},

  { key: "Information Sharing", value: [
    { key: "Third-Party Partners", value: "We may share information with trusted third-party partners and service providers who assist us in delivering our products and services." },
    { key: "Legal Compliance", value: "We may disclose your information to comply with legal obligations or respond to lawful requests from government authorities." }
  ]},

  { key: "Your Choices", value: [
    { key: "Opt-Out", value: "You can opt-out of receiving promotional communications from us by following the unsubscribe instructions provided in the communication." },
    { key: "Access and Update", value: "You have the right to access and update your personal information. If you have any questions or requests, please contact us." }
  ]},

  { key: "Security", value: "We take reasonable measures to protect your information from unauthorized access or disclosure. However, no data transmission over the internet or storage system can be guaranteed to be 100% secure." },

  { key: "Changes to This Privacy Policy", value: "We may update this Privacy Policy to reflect changes in our practices or for other operational, legal, or regulatory reasons. We encourage you to review this policy periodically." },

  { key: "Contact Us", value: "If you have any questions, concerns, or requests regarding this Privacy Policy, please contact us at [contact@momentumrobotics.com]." },

  { key: "Thank You", value: "Thank you for choosing Momentum Robotics for your robotics solutions. Your privacy and trust are important to us." }
];

const PrivacypolicyContents = () => {
    return (
      <>
        {privacyPolicyData.map((section, index) => (
          <Row className="justify-content-center" key={index}>
            <Col md="8" className='blog-content'>
              <h3 className="fw-bold text-black mb-3">{section.key}</h3>
              {Array.isArray(section.value) ? (
                <ul>
                  {section.value.map((item, itemIndex) => (
                    <li key={itemIndex} className="redable-text text-black">
                      {item.value}
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="redable-text text-black ">{section.value}</p>
              )}
            </Col>
          </Row>
        ))}
      </>
    );
  };


export default function Privacypolicy(){

    return(
        <div className="blog py-5 ">
            <Container className="">
                <Row className="mb-5 justify-content-center">
                    <Col md="8" className="text-center">
                        <h1 className="fw-bold text-black">Privacy policy</h1>
                    </Col>
                </Row>
                <div className="" id="industry-content">
                <PrivacypolicyContents/>
                
                </div>
            </Container>
        </div>
    )
} 

