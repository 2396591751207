import React from 'react';
import { Col, Row ,Container} from 'react-bootstrap';



const cookiePolicyData = [
  {
    title: "1. Introduction",
    content:
      "Welcome to our Cookie Policy. This policy details how our website uses cookies to enhance your user experience. By using our website, you consent to the use of cookies as outlined in this policy."
  },
  {
    title: "2. What Are Cookies?",
    content:
      "Cookies are small text files that are stored on your computer or mobile device when you visit a website. They help the website recognize your device and remember information about your visit, preferences, and settings."
  },
  {
    title: "3. How We Use Cookies",
    content:
      "We use cookies for various purposes, including but not limited to providing a personalized experience, improving our website, analyzing site usage, and delivering targeted advertisements. By using our website, you agree to the placement of these cookies."
  },
  {
    title: "4. Types of Cookies We Use",
    content:
      "Our website uses both session and persistent cookies. Session cookies are temporary and are deleted when you close your browser, while persistent cookies remain on your device for a set period. We also use first-party and third-party cookies for different functionalities."
  },
  {
    title: "5. Your Cookie Choices",
    content:
      "You can manage your cookie preferences by adjusting your browser settings. However, please note that disabling certain cookies may impact the functionality and features of our website. For more information on how to control cookies, please refer to your browser settings."
  },
  {
    title: "6. Changes to This Policy",
    content:
      "We may update our Cookie Policy from time to time to reflect changes in technology, applicable laws, or our services. Any changes will be posted on this page, so we encourage you to review this policy regularly for updates."
  },
  {
    title: "7. Contact Us",
    content:
      "If you have any questions about our Cookie Policy, please contact us at [your contact email]."
  }
  
];




const CookiePolicyContents = () => {
  return (
    <>
      {cookiePolicyData.map((section, i) => (
          <Row className="justify-content-center" key={i}>
             <Col md="8"  className='blog-content' >
          <h3 className="fw-bold text-black mb-3">{section.title}</h3>
          <p className="redable-text text-black "> {section.content}</p>
             </Col>
        </Row>
      ))}
    </>
  );
};



export default function CookiePolicy(){

    return(
        <div className="blog py-5 ">
            <Container className="">
                <Row className="mb-5 justify-content-center">
                    <Col md="8" className="text-center">
                        <h1 className="fw-bold text-black">Cookie Policy</h1>
                    </Col>
                </Row>
                <div className="" id="industry-content">
                <CookiePolicyContents/>
                <Row  className="justify-content-center">
                    <Col  md="8">
                    <p style={{padding:"0px",margin:"0px",color:"gray",fontSize:"12px"}}>Thank you for choosing Momentum Robotics. We look forward to providing you with innovative robotics solutions!</p>
                    <p style={{padding:"0px",margin:"0px",color:"gray",fontSize:"12px"}}>Last Updated: 28:11:2023</p>
                    </Col>
                </Row>
                </div>
            </Container>
        </div>
    )
} 

