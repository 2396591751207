import React, { createContext, useState } from 'react';
export const BlogContext = createContext();



export function BlogContextProvider(props){
    const [blog, setBlog] = useState({});
    return(
        <BlogContext.Provider value={[blog, setBlog]}>
            {props.children}
        </BlogContext.Provider>
    )
}
