import React from "react";
import {  Col, Container, Row } from "react-bootstrap";

export default function WorkWithUs(){

    return(
        <div className="bg-primary py-5 careers">
            <Container className="py-5">
                <Row>
                    <Col md="6" className="d-flex flex-column justify-content-center">
                        <h1 className="fw-bold">Want to work With us?</h1>
                        <h1 className="fw-bold">Hit the button.</h1>
                    </Col>
                    <Col md="6" className="d-flex text-end align-items-end justify-content-end">
                        {/* <Button className="banner-nav ms-3 rounded-0">Let's Work Together</Button> */}
                        <a className="banner-nav ms-3 rounded-0" href="https://wa.me/9657335122" rel="noopener noreferrer"  target='_blank' style={{ textDecoration: 'none' }}>
                            Let's Work Together
                        </a>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}