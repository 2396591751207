import React, { useContext, useEffect, useState } from "react";
import {  Nav, Navbar } from "react-bootstrap";
import {  useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { PathContext } from "../../contexts/PathContextProvider";
import { IoMdClose } from "react-icons/io";
import "../../styles/header.css";

export default function Header() {
  const navigate = useNavigate();
  const {path, setPath} = useContext(PathContext);
  const [tab, setTab] = useState("");
  const [showContactBar, setShowContactBar] = useState(true);
  const [isNavbarOpen, setIsNavbarOpen] = useState(true);



  const handleNavbarToggle = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };



  const handleNavigation = (e, id) => {
    setTab(id);
    navigate("/", { state: id });
    e.preventDefault();
    e.stopPropagation();
  };

  function handleContactBar() {
    if (window.scrollY > 400) {
      
      setShowContactBar(false);
    } else {
      setShowContactBar(true);
    }
  }
  window.addEventListener("scroll", handleContactBar);
  useEffect(() => {
    if (tab !== "") {
      const element = document.getElementById(tab);
      setPath(tab);
      if (element) {
        var headerOffset = 50;
        var elementPosition = element.offsetTop;
        var offsetPosition = elementPosition - headerOffset;
        document.documentElement.scrollTop = offsetPosition;
        document.body.scrollTop = offsetPosition;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  return (
    <div
      className="d-flex flex-column align-items-center header-container"
      style={{ position: "fixed",width: "100%", zIndex: 999 }}
    
    >
      {showContactBar && (
        <div
          className="w-100 header-content"
          style={{
            background: "linear-gradient(to right top,#49c628 10%,#3feb3f)",
          }}
        >
          <div className="header-contant-bar w-100">
            <div className="fw-bold header-contact-info" md="6">
              +91 96573 35122 | contact-us@momentumrobotics.in
            </div>
            <div className="fw-bold header-contact-info" md="6">
              Dynamic Logistics Trade Park, Bhosari Pune 411015 @Mon - Fri: 9:00
              AM - 6:00 PM
            </div>
          </div>
        </div>
      )}

      <Navbar
        expand="lg"
        className="navbar-dark w-100 d-flex p-0  flex-md-column align-items-center justify-content-center "
        style={{ background: "black" }}
       
      >
        <div className="d-flex align-items-center flex-column justify-content-center w-100  w-lg-50">
          <div className="d-flex d-lg-none w-100 px-2 py-2">
            <Navbar.Brand href="/" className="navBrand  d-lg-none d-flex align-items-center">
              <img
                src={logo}
                alt="momentum robotics solutions"
                className="mobile-logo"
                style={{
                  width:"200px",
                  height:"30px"
                }}
              />
            </Navbar.Brand>
            {isNavbarOpen ? (
              <Navbar.Toggle
                aria-controls="responsive-navbar-nav"
                onClick={handleNavbarToggle}
              >

              </Navbar.Toggle>
            ) : (
              <Navbar.Toggle
                aria-controls="responsive-navbar-nav"
                onClick={handleNavbarToggle}
              >
                <IoMdClose style={{fontSize:"30px"}} />
              </Navbar.Toggle>
            )}
          </div>

          <Navbar.Collapse
            className="w-100 p-2 p-lg-0 justify-content-center"
            id="responsive-navbar-nav"
          >
            <Navbar.Brand href="/" className="d-none d-lg-block ">
              <img
                src={logo}
                alt="momentum robotics solutions"
                className="logo"
              />
            </Navbar.Brand>
            <Nav className="">
              <Nav.Link
                className={`fw-bold ${path === "home" ? "text-primary" : ""}`}
                onClick={(e) => handleNavigation(e, "home")}
              >
                Home
              </Nav.Link>
              <Nav.Link
                className={`fw-bold ${
                  path === "industries" ? "text-primary" : ""
                }`}
                onClick={(e) => handleNavigation(e, "industries") }
              >
                Industries
              </Nav.Link>
              <Nav.Link
                className={`fw-bold ${
                  path === "products" ? "text-primary" : ""
                }`}
                onClick={(e) => handleNavigation(e, "products")}
              >
                Products
              </Nav.Link>

              <Nav.Link
                className={`${
                  path === "Launching" ? "newLaunchActive" : "newLaunch "
                } fw-bold `}
                onClick={(e) => handleNavigation(e, "Launching")}
              >
                <span className="tag">Launch</span>
                <span
                  className={`${
                    path === "Launching"
                      ? "newLaunchTextActive"
                      : "newLaunchText "
                  }`}
                >
                  Cyborg250
                </span>
              </Nav.Link>
              <Nav.Link
                className={`fw-bold ${
                  path === "aboutus" ? "text-primary" : ""
                }`}
                onClick={(e) => handleNavigation(e, "aboutus")}
              >
                About Us
              </Nav.Link>
              <Nav.Link
                href="/gallery"
                className={`fw-bold ${
                  path === "gallery" ? "text-primary" : ""
                }`}
              >
                Gallery
              </Nav.Link>
              <Nav className="get-in-touch-btn d-block d-md-none">
                <Nav.Link
                  style={{ color: "white" }}
                  onClick={(e) => handleNavigation(e, "contactus")}
                >
                  Get In Touch
                </Nav.Link>
              </Nav>
              <Nav className="get-in-touch-btn d-none d-md-block">
                <Nav.Link
                  style={{ color: "white" }}
                  onClick={(e) => handleNavigation(e, "contactus")}
                >
                  Get In Touch
                </Nav.Link>
              </Nav>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>

    </div>
  );
}
