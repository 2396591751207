import React from "react";
import quote from '../../assets/images/reviews_1.png';
import johndeere from '../../assets/images/testimonial_images/johndeere.png';
import transmonk from '../../assets/images/testimonial_images/transmonk.jpeg';
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";

export default function Testimonials() {
    var settings = {
        autoplay: true,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: true,
        arrows: false
    };

    // Static testimonials data
    const testimonials = [
        {
            quote: "This team's transformative ideas and efforts redefine industries, driving innovation to new heights.",
            author: "Mr. Suhash Dhole",
            position: "Warehouse Incharge",
            company: "John Deere Tool Warehouse.",
            image: johndeere
        },
        {
            quote: "I believe in their vision of transforming logistics for warehousing and manufacturing for next-gen growth.",
            author: "Rahul Gurang",
            position: "Factory Owner",
            company: "Transmonk Solutions",
            image: transmonk
        }
    ];

    return (
        <div className="py-5 bg-black">
            <Slider {...settings}>
                {testimonials.map((testimonial, index) => (
                    <div key={index}>
                        <Container className="my-5">
                            <Row className="mb-5 justify-content-center">
                                <Col md="1" className="d-flex justify-content-center"><img src={quote} alt="Review" /></Col>
                            </Row>
                            <Row className="justify-content-center mb-5">
                                <Col md="6" className="text-center readable-font">
                                    {testimonial.quote}
                                </Col>
                            </Row>
                            <div className="d-flex justify-content-center w-100">
                                <div><img src={testimonial.image} alt={testimonial.author} className="img-fluid" style={{ borderRadius: "50px", height: "80px", width: "80px" }} /></div>
                                <div className="d-flex flex-column ms-3">
                                    <p className="fw-bold m-0">{testimonial.author}</p>
                                    <small>{testimonial.position}</small>
                                    <p>{testimonial.company}</p>
                                </div>
                            </div>
                        </Container>
                    </div>
                ))}
            </Slider>
        </div>
    )
}
