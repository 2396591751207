import React from 'react';
import "../../styles/cubeloader.css";


function CubeLoader() {
  return (
    <div className="spinner">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>
  )
}

export default CubeLoader