import React, { useState } from "react";
import { Button, Col, Row, Modal } from "react-bootstrap";
import cyborg_img from "../../assets/images/products/cyborg.png";
import launch_background from "../../assets/images/gallery/mesh.png";
import { HiMiniCubeTransparent } from "react-icons/hi2";
import "../../styles/ModelViewer.css";
import cyborg_glb from "../../assets/3d/cyborg250.glb";
import { IoIosCloseCircleOutline } from "react-icons/io";

function ModelViewer() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



  

  return (
    <>
      <Modal show={show} onHide={handleClose} fullscreen>
        <Modal.Body className="modalPopBody">
          <span className="Modalbloom" />
          <button className="ModalCloseBtn" onClick={handleClose}>
            <IoIosCloseCircleOutline />
          </button>
          <div className="ModalLogo ">
            <h1
              className=" m-0 d-none d-md-block d-lg-block"
              style={{
                fontFamily: "Teko, sans-serif",
                fontSize: "5rem",
                zIndex: 1,
              }}
            >
              CYBORG <span style={{ color: "#4ac728" }}>250</span>
            </h1>
            <h1
              className=" m-0 d-block d-md-none d-lg-none  "
              style={{
                fontFamily: "Teko, sans-serif",
                fontSize: "2rem",
                zIndex: 1,
              }}
            >
              CYBORG <span style={{ color: "#4ac728" }}>250</span>
            </h1>
          </div>
          <model-viewer
            auto-rotate
            poster={cyborg_img}
            alt="cyborg_200"
            src={cyborg_glb}
            ar
            style={{ height: "90vh", width: "100%", zIndex: 0 }}
            camera-controls
            shadow-intensity="1"
            touch-action="pan-y"
          ></model-viewer>
        </Modal.Body>
      </Modal>
      <div className="bg-black" id="Launching" style={{ position: "relative" }}>
        <img
          src={launch_background}
          alt="mesh"
          className="position-absolute h-100  d-none d-lg-block d-md-none "
          style={{
            opacity: "0.6",
            height: "100%",
            filter: "hue-rotate(260deg)",
          }}
        />
        <img
          src={launch_background}
          alt="mesh"
          className="position-absolute  d-none d-lg-block d-md-none  h-100"
          style={{
            transform: "rotate(180deg)",
            right: "0px",
            filter: "hue-rotate(260deg)",
            opacity: "0.6",
          }}
        />
        <span className="bloom" />
        <span style={{ right: 0 }} className="bloom" />
          <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
        <Row className="rowS">
          <Col
            lg={4}
            sm={12}
            className="cols d-flex align-items-center justify-content-center "
          
          >
            <div className="position-relative w-100 h-100 d-flex justify-content-center align-items-center">
              <Button className="TDiconButton" onClick={handleShow}>
                <HiMiniCubeTransparent className="Tdicon" />
              </Button>
              <model-viewer
                auto-rotate
                poster={cyborg_img}
                alt="Neil "
                src={cyborg_glb}
                ar
                style={{ height: "60vh", width: "100%", zIndex: 0 }}
                shadow-intensity="1"
                touch-action="pan-y"
              ></model-viewer>

              <p style={{ bottom: "50px" }} className=" position-absolute hint">
                Click on <HiMiniCubeTransparent /> for 3d view
              </p>
            </div>
          </Col>
          <Col
            lg={4}
            sm={12}
            className="d-flex flex-column  position-relative justify-content-center"
          >
            <div className="d-flex flex-column gap-0 text-center ">
              <h2
                style={{
                  fontSize: "2rem",
                  fontFamily: "sans-serif",
                  fontWeight: "600",
                }}
              >
                Launching
              </h2>
              <h1
             
                style={{
                  marginTop: "-10px",
                  fontFamily: "Teko, sans-serif",
                  fontSize: "5rem",
                  zIndex: 1,
                }}
              >
                CYBORG <span style={{ color: "#4ac728" }}>250</span>
              </h1>
              <p
                className=" m-0 d-block  "
                style={{
                  fontFamily: "sans-serif",
                  fontSize: "1.5rem",
                  zIndex: 1,
                }}
              >
                "Elevating Efficiency"
              </p>
            </div>
            <div className="d-flex mt-5  w-100 justify-content-center  gap-3 px-0 px-sm-5">
              <a
                className="CyborgBtn"
                rel="noreferrer"
                href="https://1drv.ms/b/s!Aky9Kk6F7zaag6JJl1lbwFW_8-3HVA?e=u3dYNd"
                target="_blank"
              >
                Specifications
              </a>
              <a
                className="CyborgBtn"
                rel="noreferrer"
                href="https://wa.me/9657335122"
                target="_blank"
              >
                Purchase Enquiry
              </a>
            </div>
          </Col>
          <Col
            lg={4}
            sm={12}
            className="cols d-flex align-items-center justify-content-center"
      
          >
            <iframe
              style={{ height: "60%", width: "100%", zIndex: 0 }}
              src="https://www.youtube.com/embed/4SHZ8ddfq40?si=EjzZtgYz9FtRa7zi&autoplay=1&mute=1"
              frameBorder="0"
              title="YouTube Video"
              allowFullScreen
            ></iframe>
          </Col>
        </Row>
        </div>
      </div>
    </>
  );
}

export default ModelViewer;
