import { Routes, Route } from "react-router-dom";
import Home from "./components/Home/Index";
import ContactUS from "./components/ContactUs";
import Gallery from "./components/Gallery";
import Detials from "./components/Home/Details";
import Privacypolicy from "./components/Blogs/pages/Privacypolicy";
import { blogPosts } from "./components/Blogs/BlogMap";
import BlogContent from "./components/Blogs/BlogContent";
import Pagenotfound from "./components/Layout/pagenotfound";
import CookiePolicy from "./components/Blogs/pages/Cookiepolicy";
import Disclaimer from "./components/Blogs/pages/disclaimer";
import TermsOfConditions from "./components/Blogs/pages/Termandconditions";

const AppRoutes = () => {
  
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/contactus" element={<ContactUS />} />
      <Route path="/gallery" element={<Gallery />} />
      <Route path="/detail" element={<Detials />} />
      <Route path="/term-and-conditions" element={<TermsOfConditions />} />
      <Route path="/privacy-policy" element={<Privacypolicy />} />
      <Route path="/Cookie-policy" element={<CookiePolicy />} />
      <Route path="/disclaimer" element={<Disclaimer />} />
      {blogPosts.map((posts, i) => {
        return (
          <Route
            path={`/blog/${posts.fileTitle}`}
            key={i}
            element={<BlogContent pageContents={posts.file} />}
          />
        );
      })}
       <Route path="*" element={<Pagenotfound/>} />
    </Routes>
  );
};

export default AppRoutes;
