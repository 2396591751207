import React, { useContext, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { PathContext } from "../../contexts/PathContextProvider";

export default function Detials() {
    const {setPath} = useContext(PathContext);
    useEffect(() => {
        window.scrollTo(0, 0)
        setPath("details");
    }, [setPath])
    const location = useLocation();
    return (
        <div className="product-details py-5 vh-100" style={{ background: "#121212" }}>
            <Container className="">
                <Row className="mb-5">
                    <div className="d-flex justify-content-center">
                        <h1 className="m-0" style={{ fontFamily: 'Teko, sans-serif', fontSize: "3rem" }}>{location.state.name.substring(0, location.state.name.lastIndexOf(" "))}</h1>
                        <h1 className="text-primary m-0 ms-3" style={{ fontFamily: 'Teko, sans-serif', fontSize: "3rem" }}>{parseInt(location.state.name.split(" ")[location.state.name.split(" ").length - 1])}</h1>
                    </div>
                    <h5 className="fw-light mb-4 text-primary d-flex justify-content-center" style={{ fontSize: "1.1rem" }}><strong>{location.state.tagline}</strong></h5>
                </Row>
                <Row>
                    <Col md="6">
                        <img src={location.state.img[0]} alt={`${location.state.name} ${location.state.model}`} className="img-fluid" />
                    </Col>
                    <Col md="5" className="d-flex mt-3 prod-desc ms-auto flex-column align-items-start">
                        <div className="d-flex">
                            <h1>{location.state.name} <strong>{location.state.model}</strong></h1>
                        </div>
                        <p className="readable-font">{location.state.desc}</p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}