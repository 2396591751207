/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from 'react';
import { Button, Card, Carousel } from 'react-bootstrap';
function CustomCarousel() {
    const [images, setImages] = useState([]);
    function importAll(r) {
        return r.keys().map(r);
    }
    useEffect(() => {
        let listOfImages = importAll(require.context('../../assets/images/landing_casousel', false, /\.(png|jpe?g|svg)$/));
        setImages(listOfImages);
    }, [])

    const handleClick = (e, id) => {
        const element = document.getElementById('products');
        if (element) {
            var headerOffset = 148;
            var elementPosition = element.offsetTop;
            var offsetPosition = elementPosition - headerOffset;
            document.documentElement.scrollTop = offsetPosition;
            document.body.scrollTop = offsetPosition;
        }
    }

    return (
        <div className='carousel-container' id="home" style={{ position: "relative" }}>
            <div className='landing-page-lg'>
                <div className='landing-slider'>
                    <Carousel>
                        {images.length > 0 && images.map((image, index) => (
                            <Carousel.Item key={index}>
                                <img src={image} className='img-fluid' style={{ height: "calc(100vh - 110px)" }} />
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </div>
                <div className='landing-slider-overlay'>
                    <div className='landing-card'>
                        <div className='landing-card-inner'>
                            <div className=''>
                                <h1>MOMENTUM</h1>
                                <h1>ROBOTICS</h1>
                            </div>
                            <div className='caption'>
                                <p>Elevating Efficiency in Material Handling</p>
                            </div>
                            <Button className='banner-nav rounded-0' onClick={handleClick}>Our Solutions</Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='landing-page-md flex-column'>
                <div className='landing-slider-md'>
                    <Carousel>
                        {images && images.map((image, index) => (
                            <Carousel.Item key={index}>
                                <img src={image} className='img-fluid' />
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </div>
                <div className='landing-card-md w-100'>
                    <Card className='rounded-0 flex-row align-items-center flex-wrap'>
                        <Card.Body className='d-flex flex-column justify-content-center'>
                            <Card.Title>
                                <h1 style={{ fontSize: "4rem", fontFamily: "Teko, sans-serif" }}>MOMENTUM</h1>
                                <h1 style={{ fontSize: "4rem", fontFamily: "Teko, sans-serif" }}>ROBOTICS</h1>
                            </Card.Title>
                            <Card.Text>
                                <span className='readable-font'>Elevating Efficiency in Material Handling</span>
                            </Card.Text>
                        </Card.Body>
                        <div className='d-flex justify-content-end w-md-100'>
                            <Button className='landing-card-btn rounded-0' onClick={handleClick}>Our Solutions</Button>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default CustomCarousel;