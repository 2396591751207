import React from "react";
import ContactUS from "../ContactUs";
import AboutUs from "./AboutUs";
import Industries from "./Industries";
import LandingCasousel from "./LandingCasousel";
import OurProducts from "./OurProducts";
import Technology from "./Technology";
import Testimonials from "./Testimonials";
import WorkWithUs from "./WorkWithUs";
import ModelViewer from "./ModelViewer";

export default function Index() {
  return (
    <>
      <LandingCasousel />
      <Industries />
      <ModelViewer />
      <OurProducts />
      <Technology />
      <AboutUs />
      <Testimonials />
      <WorkWithUs />
      <ContactUS />
    </>
  );
}
