/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import whatsappico from "../../assets/images/whatsapp-coloured.png";
import { PathContext } from "../../contexts/PathContextProvider";
import { AlertContext } from "../../contexts/AlertContext";
import Footer from "./Footer";
import Header from "./Header";
import LoadingScreen from "./LoadingScreen";
import { useLocation } from "react-router-dom";

export default function Layout({ children }, classes) {
  const {path } = useContext(PathContext);
  const [showAlert, setShowAlert] = useContext(AlertContext);
  const location = useLocation();
  const [loading, setLoading] = useState(location.pathname === '/'?true:false)
  console.log(location.pathname);
  useEffect(() => {
    let element = document.getElementById(path);
    if (element) {
      var headerOffset = 70;
      var elementPosition = element.offsetTop;
      var offsetPosition = elementPosition - headerOffset;
      document.documentElement.scrollTop = offsetPosition;
      document.body.scrollTop = offsetPosition;
    }
  }, [path]);

  useEffect(() => {

      setTimeout(() => {
        setLoading(false);
      }, 1000);
    

  }, []);

  console.log(location.pathname === '/')
  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <div className="loading" id="loader">
        <div className="uil-ring-css">
          <div className="sendmailloader"></div>
        </div>
      </div>
      <div className={`d-flex flex-column vh-100  ${classes.toString()}`}>
        <Header />
        <main className="main-container">{children}</main>
         
            <Footer />
          
          <a
            className="whatsapp-ico"
            href="https://wa.me/9657335122"
            target="_blank"
          >
            <img src={whatsappico} alt="whatsapp" />
          </a>
      </div>
    </>
  );
}
