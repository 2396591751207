const fileContext = require.context("../../blogs", true, /\.txt$/);

const fileArray = fileContext.keys().map((key) => {
  const fileName = key.replace('./', '').replace('.txt', '');
  const file = fileContext(key);
  const pathSegments = key.split('/');
  const category = pathSegments.length > 2 ? pathSegments[1] : 'general';
  return { fileTitle: fileName, file, category };
});

export const blogPosts = fileArray;
