import React, { createContext, useState } from 'react';
export const PathContext = createContext();



export function PathContextProvider(props){
    const [path, setPath] = useState("");
    return(
        <PathContext.Provider value={{path, setPath}}>
            {props.children}
        </PathContext.Provider>
    )
}
