import React  from "react";
import {  Card } from "react-bootstrap";
import technology1 from '../../assets/images/technology_1.png';
import technology2 from '../../assets/images/technology_2.png';
import technology3 from '../../assets/images/technology_3.png';
import technology4 from '../../assets/images/technology_4.png';


export default function Technology() {

    const handleClick = (link) => {
        window.location.href = link;
      };
    
        
    return (
        <div className="mt-5" style={{background: '#000'}} id="features">
            <div className='mb-5'>
                <h1 className='text-center fw-bold'>Technology led Features</h1>
            </div>
            <div className="technology d-flex">
                <div className="feature">
                    <img src={technology1} alt="Autonomous  Mapping and Planning" className="img-fluid images" />
                    <div className="feature-title">
                        <h1 className="technology-card-number">01.</h1>
                        <h2 className="fw-bold technology-card-title">Autonomous Mapping and Planning</h2>
                    </div>
                    <div className="feature-overlay h-100 w-100" >
                        <Card className="w-100 tech-card" onClick={()=>handleClick('/blog/technology/autonomous_mapping_and_planning')}>
                            <Card.Body className="w-100 d-flex align-items-center"  >
                                <div className="feature-card h-100 d-flex flex-column justify-content-center" >
                                    <h1 className="technology-card-number">01.</h1>
                                    <h2 className="fw-bold technology-card-title text-white">Autonomous Mapping and Planning</h2>
                                    <p className="technology-card-desc">Our solutions are technology driven, we impliment advance technology to acheive highest order of precision using our AMRs.</p>
                                    <a className='bg-transparent text-white border-white read-more-btn' style={{ cursor: 'pointer' }} href="/blog/technology/Autonomous_Mapping_and_planning" variant="">Read More</a>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
                <div className="feature">
                    <img src={technology2} alt="Autonomous  Mapping and Planning" className="img-fluid images" />
                    <div className="feature-title">
                        <h1 className=" technology-card-number">02.</h1>
                        <h2 className="fw-bold technology-card-title">Advanced Robotics Fleet</h2>
                    </div>
                    <div className="feature-overlay h-100 w-100">
                        <Card className="w-100 tech-card" onClick={()=> handleClick('/blog/technology/advanced_robotics_fleet')}>
                            <Card.Body className="d-flex align-items-center w-100" >
                                <div className="feature-card h-100 d-flex flex-column justify-content-center">
                                    <h1 className="technology-card-number" >02.</h1>
                                    <h2 className="fw-bold technology-card-title text-white">Advanced Robotics Fleet</h2>
                                    <p className="technology-card-desc">We make available our platform as Robotics fleet to carry of various tasks collaborately with exsting resources.</p>
                                    <a className='bg-transparent text-white border-white read-more-btn' style={{ cursor: 'pointer' }} href="/blog/technology/Advanced_Robotics_Fleet" variant="">Read More</a>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
                <div className="feature">
                    <img src={technology3} alt="Autonomous  Mapping and Planning"  className="img-fluid images" />
                    <div className="feature-title">
                        <h1 className=" technology-card-number">03.</h1>
                        <h2 className="fw-bold technology-card-title">Dashboard Analytics</h2>
                    </div>
                    <div className="feature-overlay h-100 w-100">
                        <Card className="w-100 h-100 tech-card" onClick={()=> handleClick('/blog/technology/dashboard_and_analytics')}>
                            <Card.Body className="d-flex align-items-center w-100"  >
                                <div className="feature-card h-100 d-flex flex-column justify-content-center">
                                    <h1 className="technology-card-number" >03.</h1>
                                    <h2 className="fw-bold technology-card-title text-white">Dashboard and Analytics</h2>
                                    <p className="technology-card-desc">We offer Remote dashboard for robot's managment and task allocation with generation of advanced analytical reports for business processes.</p>
                                    <a className='bg-transparent text-white border-white read-more-btn' style={{ cursor: 'pointer' }}  href="/blog/technology/Dashboard_and_Analytics" variant="">Read More</a>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
                <div className="feature">
                    <img src={technology4} alt="Autonomous  Mapping and Planning" className="img-fluid images" />
                    <div className="feature-title">
                        <h1 className=" technology-card-number">04.</h1>
                        <h2 className="fw-bold technology-card-title">Easy Front and back Integration</h2>
                    </div>
                    <div className="feature-overlay h-100 w-100" >
                        <Card className="w-100 tech-card" onClick={()=> handleClick('/blog/technology/easy_front_and_back_integration')}>
                            <Card.Body className="d-flex align-items-center w-100">
                                <div className="feature-card h-100 d-flex flex-column justify-content-center">
                                    <h1 className="technology-card-number" >04.</h1>
                                    <h2 className="fw-bold technology-card-title text-white">Easy front and back integration</h2>
                                    <p className="technology-card-desc">Our solutions is collaborative and easily integrable with exsiting resources; Company ERP and machinary.</p>
                                    <a className='bg-transparent text-white border-white read-more-btn' style={{ cursor: 'pointer' }} href="/blog/technology/Easy_front_and_back_integration" >Read More</a>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    )
}