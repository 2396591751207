import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import logo from "../../assets/images/logo.png";
import "../../styles/footer.css";

const FooterLinks = {
  company: [
    { title: "Terms and Conditions", href: "/term-and-conditions" },
    { title: "Privacy Policy", href: "/privacy-policy" },
    { title: "Cookie Policy", href: "/cookie-policy" },
    { title: "Disclaimer", href: "/disclaimer" },
  ],
  Industries: [
    {
      title: "Automotive Manufacturing Enabler",
      href: "/blog/industry/Automotive_Manufacturing_Enabler",
    },
    {
      title: "Heavy industry material mover",
      href: "/blog/industry/heavy_industry_material_mover",
    },
    { title: "FMCG/Warehousing", href: "/blog/industry/fmcg_warehousing" },
  ],
};

const FooterLink = ({ href, content, onClick }) => {
  return (
    <a className="footer-link" onClick={onClick} href={href}>
      {content}{" "}
    </a>
  );
};

export default function Footer() {
  return (
    <footer className="footer  pt-3 pb-2 mt-auto px-5">
      <Container className="">
        <Row className="mt-2">
          <Col md="3" className="ms-auto mb-4">
            <img
              src={logo}
              alt={"momentum robotics solutions"}
              className="img-fluid mb-4"
              style={{ width: "300px" }}
            />
            <p className="text-white fw-light">
              A product technology company providing
              <br /> next gen material handeling solutions
            </p>
            <div className="d-flex justify-content-start align-items-center gap-4">
              <a
                href="https://www.linkedin.com/company/momentum-robotics/mycompany/"
                target="_blank"
                className="footer-icon"
                rel="noreferrer"
              >
                <i className="fab fa-facebook"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/momentum-robotics/mycompany/"
                target="_blank"
                className="footer-icon"
                rel="noreferrer"
              >
                <i className="fab fa-instagram"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/momentum-robotics/mycompany/"
                target="_blank"
                className="footer-icon"
                rel="noreferrer"
              >
                <i className="fab fa-linkedin"></i>
              </a>
              <a
                href="https://momentumrobotics.in/"
                target="_blank"
                className="footer-icon"
                style={{ fontSize: "25px" }}
                rel="noreferrer"
              >
                {" "}
                <i className="fab fa-chrome"></i>{" "}
              </a>
            </div>
          </Col>
          <Col md="3" className="ms-auto text-center mb-4">
            <div className="text-start">
              <h3 className="footer-header">Contact Us</h3>
              <p className="text-white fw-light ">
                <strong>Address:</strong> Dynammic Logistics Trade Park, <br />
                Bhosari Pune MH-411015
              </p>
              <p className="text-white fw-light">
                <strong>Phone:</strong> +91 965733 5122
              </p>
            </div>
          </Col>
          <Col md="2" className="ms-auto text-center mb-4">
            <div className="text-start d-flex flex-column">
              <h3 className="footer-header">Company</h3>
              {FooterLinks.company.map((a, _i) => (
                <FooterLink key={_i} content={a.title} href={a.href} />
              ))}
            </div>
          </Col>
          <Col md="3" className="ms-auto text-start">
            <div className="text-start d-flex flex-column">
              <h3 className="footer-header">Industries</h3>
              {FooterLinks.Industries.map((a, _i) => (
                <FooterLink key={_i} content={a.title} href={a.href} />
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
