import React from 'react';
import { Col, Row ,Container} from 'react-bootstrap';



const disclaimerData = [
    {
      title: "1. No Legal Advice",
      content:
        "The information provided on this website is for general informational purposes only and is not intended as legal advice. Consult with a qualified professional for advice regarding your individual situation."
    },
    {
      title: "2. Accuracy of Information",
      content:
        "While we strive to provide accurate and up-to-date information, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, or suitability of the information contained on this website."
    },
    {
      title: "3. External Links",
      content:
        "This website may contain links to external websites that are not provided or maintained by us. We do not guarantee the accuracy, relevance, timeliness, or completeness of any external site linked from or to this website."
    },
    {
      title: "4. Personal Responsibility",
      content:
        "The use of this website and its content is at your own risk. You are responsible for verifying any information before relying on it. Your use of the website constitutes your agreement to the terms of this disclaimer."
    },
    {
      title: "5. Changes to Disclaimer",
      content:
        "We reserve the right to modify, add, or remove any part of this disclaimer at any time. It is your responsibility to check this disclaimer periodically for changes. Your continued use of the website following the posting of changes constitutes acceptance of those changes."
    },
    {
      title: "6. Contact Information",
      content:
        "If you have any questions about this disclaimer, please contact us at [your contact email]."
    }
    // Add more objects as needed for your disclaimer
  ];
  





const DisclaimerContents = () => {
  return (
    <>
      {disclaimerData.map((section, index) => (
          <Row className="justify-content-center" key={index}>
             <Col md="8"  className='blog-content'>
          <h3 className="fw-bold text-black mb-3">{section.title}</h3>
          <p className="redable-text text-black"> {section.content}</p>
             </Col>
        </Row>
      ))}
    </>
  );
};



export default function Disclaimer(){

    return(
        <div className="blog py-5 ">
            <Container className="">
                <Row className="mb-5 justify-content-center">
                    <Col md="8" className="text-center">
                        <h1 className="fw-bold text-black">Disclaimer</h1>
                    </Col>
                </Row>
                <div className="" id="industry-content">
                <DisclaimerContents/>
                <Row  className="justify-content-center">
                    <Col  md="8">
                    <p style={{color:"#000",padding:"0px",margin:"0px",fontSize:"12px"}}>Thank you for choosing Momentum Robotics. We look forward to providing you with innovative robotics solutions!</p>
                    <p style={{color:"#000",padding:"0px",margin:"0px",fontSize:"12px"}}>Last Updated: 28:11:2023</p>
                    </Col>
                </Row>
                </div>
            </Container>
        </div>
    )
} 

