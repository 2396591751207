/* eslint-disable no-unused-vars */
import React, { useContext, useState } from "react";
import { Col, Container, Row, Form, Button, ToastContainer, Toast} from "react-bootstrap";
import { AlertContext } from "../contexts/AlertContext";
import {MdMarkEmailRead, MdMarkEmailUnread} from "react-icons/md"
export default function ContactUS() {
    const [showAlert, setShowAlert] = useContext(AlertContext);
    // const [errors, setErrors] = useState({name: null, email: null, message: null});
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState();
   
    const handleSubmit = () => {

        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/ 
        if(!name || !email || !message  || name.length === 0 || email.length === 0 || message.length === 0){
            setError(true)
        }else if(!emailRegex.test(email)){
            setError(true)
            setErrorMsg("Email not valid")
        }else{
            setErrorMsg(null)
            document.getElementById("loader").style.display = "block";
            fetch("/app/email", {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({name: name, email: email, message: message})
            }).then(res => res.json()).then(data => {
                if(data.success){
                    setShowAlert({open: true, type: "primary", message: "Your inquiry has been sent to Momentum Robotics successfully!", status: true});
                    setError(false);
                    setName("");
                    setEmail("");
                    setMessage("");
                    document.getElementById("name").value = "";
                    document.getElementById("email").value = "";
                    document.getElementById("message").value = "";
                }else{
                    setShowAlert({open: true, type: "danger", message: "Not able to send an email now, Please use WhatsApp chat by clicking the WhatsApp icon.", status: false})
                }
                document.getElementById("loader").style.display = "none";
            }).catch(err => {
                setShowAlert({open: true, type: "danger", message: "Not able to send an email now, Please use WhatsApp chat by clicking the WhatsApp icon.", status: false})
                document.getElementById("loader").style.display = "none";
            })
        }
    }

    const handleName = (e) => {
        if(e.target.value !== "" || e.target.value !== null){
            setName(e.target.value);
        }
    }

    const handleEmail = (e) => {
        if(e.target.value !== "" || e.target.value !== null){
            setEmail(e.target.value);
        }
    }

    const handleMessage = (e) => {
        if(e.target.value !== "" || e.target.value !== null){
            setMessage(e.target.value);
        }
    }

    return (
        <div className="contactus" id="contactus" style={{ background: "#171717",minHeight:"80vh",display:"flex",justifyItems:"center",alignItems:"center" ,position:"relative"}}>
            <div style={{position:"absolute",boxShadow:" 0px 0px 500px 200px rgba(28,111,3,0.2)",top:0,left:0,zIndex:0}} />
            <div style={{position:"absolute",boxShadow:" 0px 0px 500px 200px rgba(28,111,3,0.2)",bottom:0,right:0,zIndex:0}} />
            <Container className="py-5">
                <Row>
                    <Col className="d-flex justify-content-center">
                        <h1 className="fw-bold">Contact Us</h1>
                    </Col>
                </Row>
                <form className="m-5"  >
                    <Row className="justify-content-center">
                        <Col md="9">
                            <Row className="mb-3">
                                <Col md="6" className="mb-3">
                                    <input type="text" name="name" id="name" placeholder="Name" className="custom-input p-3 w-100" onChange={handleName} value={name}/>
                                </Col>
                                <Col md="6" className="mb-3">
                                    <input type="email" name="email" id="email" placeholder="Email" className="custom-input p-3  w-100" onChange={handleEmail} value={email}/>
                                    <p className="text-danger">{error && errorMsg  ? errorMsg : ""}</p>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md="12">
                                    <textarea name="message" id="message" placeholder="Message" className="custom-input rounded-0 py-2 px-3 w-100" rows={6} style={{ borderRadius: "15px" }} value={message} onChange={handleMessage}></textarea>
                                    <p className="text-danger">{error && !message ? "Please enter message or query" : ""}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-center mt-3">
                                    <Button className="col-12 col-md-6 col-lg-6 text-white rounded-0 p-3 fw-bold" onClick={() => handleSubmit()}>Submit</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </form>
            </Container>

                <div style={{minHeight:"100vh",zIndex:9,background:"rgba(0,0,0,0.8)",display:showAlert.open?"flex":"none",alignItems:'center',justifyContent:'center',width:'100%',position:"absolute"}}>
                {/* bg={showAlert.type} */}
                <Toast style={{boxShadow:"0px 0px 100px 10px rgba(69, 252, 0, 0.2)",width:"500px"}}  show={showAlert.open}>
                    <Toast.Body className="text-white d-flex flex-column justify-content-center align-items-center p-5">
                        {(showAlert.status === true) ?
                            <MdMarkEmailRead color={"green"} style={{fontSize:"50px"}}/>:
                            <MdMarkEmailUnread color={"red"} style={{fontSize:"50px"}}/>
                        }
                        <p className="text-center py-3">{showAlert.message}</p> 
                        <Button onClick={() => setShowAlert({open: false})} style={{cursor: 'pointer', fontWeight: "bold",color:"#fff",borderRadius:"0px",marginTop:"20px"}}>Close</Button>
                    </Toast.Body>
                </Toast>
                </div>
                
        </div>
    )
}