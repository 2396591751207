import React from 'react';
import { Col, Row ,Container} from 'react-bootstrap';



const termsData = [
  {
    title: "1. Acceptance of Terms",
    content: "By accessing or using the Momentum Robotics website or any of our services, you agree to abide by these Terms of Service. If you do not agree with any part of the terms, please refrain from using our website and services.",
  },
  {
    title: "2. Description of Services",
    content: "Momentum Robotics is a robotics startup based in Pune, India, focused on solving material handling challenges for warehouses, production facilities, and manufacturing units. We develop cutting-edge industrial robots with an emphasis on future mobility, impressive payload capacities, and cost efficiency. Our services include the provision of tailored solutions and seamless implementation across industries.",
  },
  {
    title: "3. Start-up India Initiative",
    content: "As part of the Start-up India initiative, Momentum Robotics is committed to delivering high-quality, certified solutions. Our team comprises certified experts dedicated to providing innovative robotics solutions to help organizations adapt to market demands and changes.",
  },
  {
    title: "4. User Responsibilities",
    content: "You agree to use Momentum Robotics services responsibly and in compliance with all applicable laws and regulations. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.",
  },
  {
    title: "5. Intellectual Property",
    content: "All content and materials available on the Momentum Robotics website, including but not limited to text, graphics, logos, button icons, images, audio clips, and software, are the property of Momentum Robotics and are protected by intellectual property laws.",
  },
  {
    title: "6. Privacy Policy",
    content: "Our Privacy Policy outlines how we collect, use, and protect your personal information. By using our website and services, you consent to the collection and use of your information as described in our Privacy Policy.",
  },
  {
    title: "7. Limitation of Liability",
    content: "Momentum Robotics shall not be liable for any direct, indirect, incidental, special, or consequential damages resulting from the use or inability to use our website or services.",
  },
  {
    title: "8. Modifications to Terms",
    content: "Momentum Robotics reserves the right to modify or replace these Terms of Service at any time. It is your responsibility to check the terms periodically for changes. Your continued use of the website and services after the posting of any changes constitutes acceptance of those changes.",
  },
  {
    title: "9. Governing Law",
    content: "These Terms of Service shall be governed by and construed in accordance with the laws of India.",
  },
];


const TermsandConditionsContents = () => {
  return (
    <>
      {termsData.map((section, index) => (
          <Row className="justify-content-center" key={index}>
             <Col md="8" className='blog-content'>
          <h3 className="fw-bold text-black mb-3">{section.title}</h3>
          <p className="redable-text text-black"> {section.content}</p>
             </Col>
        </Row>
      ))}
    </>
  );
};



export default function TermsOfConditions(){

    return(
        <div className="blog py-5 ">
            <Container className="">
                <Row className="mb-5 justify-content-center">
                    <Col md="8" className="text-center">
                        <h1 className="fw-bold text-black">Terms and Conditions</h1>
                    </Col>
                </Row>
                <div className="" id="industry-content">
                <TermsandConditionsContents/>
                <Row  className="justify-content-center">
                    <Col  md="8">
                    <p style={{padding:"0px",margin:"0px",color:"gray",fontSize:"12px"}}>Thank you for choosing Momentum Robotics. We look forward to providing you with innovative robotics solutions!</p>
                    <p style={{padding:"0px",margin:"0px",color:"gray",fontSize:"12px"}}>Last Updated: 28:11:2023</p>
                    </Col>
                </Row>
                </div>
            </Container>
        </div>
    )
} 

