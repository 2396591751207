import React from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import aboutusimg from '../../assets/images/about-us-img.jpg';
import aboutusimg2 from '../../assets/images/about_us_ico.png'

export default function AboutUs() {
    function handleRedirection() {
        const element = document.getElementById('contactus');
        if (element) {
            var headerOffset = 148;
            var elementPosition = element.offsetTop;
            var offsetPosition = elementPosition - headerOffset;
            document.documentElement.scrollTop = offsetPosition;
            document.body.scrollTop = offsetPosition;
        }
    }
    return (
        <div className="py-5" id="aboutus" style={{ background: '#171717' }}>
            <Container>
                <div className="mb-5 text-center">
                    <div>
                        <h1 className="fw-bold text-white">About Us</h1>
                    </div>
                </div>
                <Row className="justify-content-center">
                    <Col md="6" className="d-flex flex-column align-items-center justify-content-center mb-4">
                        <p className="mb-4 fw-light readable-font">
                            We are a young robotics startup based in Pune, India, with a clear mission: solving material handling challenges for warehouses, production facilities, and manufacturing units. In this era of Industry 4.0, organizations require intelligent solutions to efficiently adapt to market demands and changes.
                        </p>
                        <br />
                        <p className="mb-4 fw-light readable-font">
                            At Momentum Robotics, we develop cutting-edge products with an eye on the future of mobility. Our industrial robots come in a variety of versions and boast impressive payload capacities. Our commitment lies in reducing operating costs and ensuring a swift return on investment.
                        </p><br />
                        <p className="mb-4 fw-light readable-font">
                            As part of the Start-up India initiative, our team comprises certified experts dedicated to providing tailored solutions and seamless implementation across industries. We firmly believe in elevating internal logistics operations through the power of technology.
                        </p>
                        <div className="w-100 d-flex justify-content-center mb-4">
                            <Button className="about-nav-btn rounded-0 px-5" style={{ fontSize: "1.5rem" }} onClick={() => handleRedirection()}>Contact Us</Button>
                        </div>
                    </Col>
                    <Col md="5" className="d-flex justify-content-center align-items-center mb-4 ms-auto" style={{ position: "relative" }}>
                        <img src={aboutusimg} alt="Momentum Robotics Solutions" className="img-fluid" style={{ height: '100%', width: "500px" }} />
                        <div className="our-mission" style={{ position: "absolute", width: "400px", height: '300px' }}>
                            <Card className="bg-primary rounded-0 h-100">
                                <Card.Body>
                                    <img src={aboutusimg2} alt="about" className="img-fluid mb-3" />
                                    <Card.Title className="text-white">
                                        <h2 className="fw-bold" style={{ fontFamily: "Teko,sans-serif", fontSize: '3rem' }}>OUR MISSON</h2>
                                    </Card.Title>
                                    <p className="text-white" style={{ fontSize: '1.2rem' }}>To provide best in class customized Automation Solutions for Internal Logistics and Material Movement.</p>
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}