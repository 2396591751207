import React from 'react'

function Pagenotfound() {
  return (
    <div style={{minHeight:"50vh",display:"flex",alignItems:"center",justifyContent:"center"}}>
        <h1>Resource does not exist ! </h1>
    </div>
  )
}

export default Pagenotfound