import React, { createContext, useState } from 'react';
export const AlertContext = createContext();



export function AlertContextProvider(props){
    const [showAlert, setShowAlert] = useState({open: false, message: "", type: "danger", status: true});
    return(
        <AlertContext.Provider value={[showAlert, setShowAlert]}>
            {props.children}
        </AlertContext.Provider>
    )
}
