import React from 'react'
import CubeLoader from '../Loaders/cubeLoader'
import ProgressLoader from '../Loaders/ProgressLoader'
import logo from "../../assets/images/loaderlogo.png"

function LoadingScreen() {
  return (
    <div style={{minHeight:"100vh",background:"#000",display:"flex",alignItems:"center",justifyContent:'center',flexDirection:"column",position:"absolute",width:"100%",zIndex:999}}>
    <img src={logo} alt='>' style={{height:"75px",position:"absolute",zIndex:0}} />
    <CubeLoader/> 
    <div style={{marginTop:"250px"}}>
    <ProgressLoader/>
   </div>  
</div>
  )
}

export default LoadingScreen