import React, { useEffect, useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import "../../styles/blog.css";

function BlogContent({ title, pageContents }) {
  const [htmlFileString, setHtmlFileString] = useState('');

  useEffect(() => {
    async function fetchHtml() {
      try {
        const response = await fetch(pageContents);
        const htmlContent = await response.text();
        setHtmlFileString(htmlContent);
      } catch (error) {
        console.error('Error fetching HTML:', error);
      }
    }

    fetchHtml();
  }, [pageContents]);

  return (
    <div className="blog">
      <Container >
        <Row className="mb-1 justify-content-center">
          <Col md="8" className="text-center">
            <h1 className="fw-bold text-black">{title}</h1>
          </Col>
        </Row>

        <Row className="mb-5 justify-content-center">
          <Col md="8">
            <h1 className="fw-bold text-black">{title}</h1>
            <div dangerouslySetInnerHTML={{ __html: htmlFileString }} className="blog-content"></div>
        
          </Col>
        </Row>
        
      </Container>
    </div>
  );
}

export default BlogContent;
