import React, { useState, useContext, useEffect } from "react";
import { data } from "../jsondata/gallery";
import {  Col, Container, Row } from "react-bootstrap";
import { PathContext } from "../contexts/PathContextProvider";
import Modal from 'react-bootstrap/Modal';


export default function Gallery() {
    const { setPath} = useContext(PathContext);
    const [showImage, setShowImage] = useState(false);
    const [image, setImage] = useState({});
    useEffect(() => {
        setPath("gallery")
    }, [setPath])

    function handleImageZoom(product){
        setShowImage(true)
        setImage(product);
    }
    return (
        <div className="gallery py-5" style={{ background: '#121212' }}>
            <Container>
                <Row className="mb-5">
                    <Col className="text-center">
                        <h1 className="fw-bold">Gallery</h1>
                    </Col>
                </Row>
                <Row>
                    {data.map((product, index) => (
                        <Col md="4" key={index} className="text-center gallery-img mb-4" style={{ position: "relative" }} onClick={() => handleImageZoom(product)}>
                            <img src={product.image} alt={product.desc} className=" mb-3" height={"170px"} width={"100%"} id={index} />
                            <div className="product-name">{product.desc}</div>
                        </Col>
                    ))}
                </Row>
            </Container>
            <Modal show={showImage} onHide={() => setShowImage(false)} size="lg" centered>
                    <Modal.Body className="bg-white text-black p-0">
                        <img src={image.image}alt="img"  className="img-fluid"/>
                    </Modal.Body>
                </Modal>
        </div>
    )
}