import React from 'react';
import { createRoot } from 'react-dom/client'
import './scss/custom.scss';
import './index.css';

import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './routes';
import Layout from './components/Layout/Layout';
import { PathContextProvider } from './contexts/PathContextProvider';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { BlogContextProvider } from './contexts/BlogContextProvider';
import { AlertContextProvider } from './contexts/AlertContext';
import posthog from 'posthog-js'

posthog.init('phc_T6vrJtc2rdaUaXcImC5e3N73Ad7z7uqUKbi2dUUGrtw', { api_host: 'https://app.posthog.com' })

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <AlertContextProvider>
      <BlogContextProvider>
        <PathContextProvider>
          <Layout>
            <AppRoutes />
          </Layout>
        </PathContextProvider>
      </BlogContextProvider>
    </AlertContextProvider>
  </BrowserRouter>
);
